import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "@components/layout"
import Seo from "@components/seo"
import { kebabCase } from "lodash"
import { GatsbyImage } from "gatsby-plugin-image"
import { FcGlobe } from "react-icons/fc"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Publishers from "@components/Games/Publishers"
import * as containerStyles from "@components/Queries/NewsCard.module.css"
import GameCard from "@components/GameCard/GameCard"

const PublisherSingleTemplate = ({ data, pageContext, location }) => {
  const publisher = data.contentfulEditorial
  const articulos = data.allContentfulArticulos.edges

  return (
    <Layout location={location}>
      <Seo
        title={`Editorial ${publisher.title}`}
        description={`Juegos de la editorial ${publisher.title}`}
        image={`${publisher.logo.file.url}`}
      />

      <div
        className={`pattern-grid-lg relative flex flex-col items-center justify-center w-full pt-24 md:pt-24 pb-12 md:pb-32 mx-auto bg-green-600 text-green-700 bg-fixed`}
      >
        <div
          className={`
          relative z-50 flex flex-col items-center justify-center w-full px-6 pt-0 pb-0 mx-auto text-left max-w-7xl md:pt-12 md:pb-12
          `}
        >
          <h1 className="pt-12 pb-6 font-serif text-3xl font-bold leading-relaxed text-left text-white md:pt-0 md:text-6xl">
            {publisher.title}
          </h1>

          {publisher.comunidad && (
            <AnchorLink
              className="relative px-12 btn yellow"
              href="#comunidad"
            >
              Ver Editorial en Comunidad
              <FcGlobe className="absolute text-2xl -top-3 -right-3" />
            </AnchorLink>
          )}
        </div>

        <div className="absolute bottom-0 left-0 right-0 w-full overflow-hidden">
          <div className="relative">
            <svg
              viewBox="0 0 1428 174"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                  transform="translate(-2.000000, 44.000000)"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                >
                  <path
                    d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                    opacity="0.100000001"
                  ></path>
                  <path
                    d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                    opacity="0.100000001"
                  ></path>
                  <path
                    d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                    id="Path-4"
                    opacity="0.200000003"
                  ></path>
                </g>
                <g
                  transform="translate(-4.000000, 76.000000)"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                >
                  <path d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"></path>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>

      <div className="relative z-10 flex flex-col w-full mx-auto -mt-16 max-w-7xl">
        <div className="relative w-full px-2 pt-12 mx-auto">
          <div className="grid grid-cols-1 gap-4 p-3 pb-12 mx-auto mt-16 bg-white max-w-7xl sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 ">
            {articulos.map(({ node }) => {
              return <GameCard card={node} key={node.slug} />
            })}
          </div>
        </div>
      </div>
      {publisher.comunidad && (
        <div
          className="px-3 py-24 text-gray-200 bg-gray-100 pattern-grid-lg"
          id="comunidad"
        >
          <div className="relative z-50 flex flex-col items-center justify-center text-center">
            <FcGlobe className="mb-1 text-3xl" />
            <h1 className="w-full font-mono text-xl text-gray-800 md:text-3xl">
              Comunidad
            </h1>
          </div>
          <div className="w-full max-w-xl px-0 pt-6 pb-12 mx-auto">
            {/* {publisher.comunidad.map(({ node }) => {
              return <NewsCard card={node}  />
            })} */}
            {publisher.comunidad.map((item, i) => (
              <div key={item.slug} className={containerStyles.item}>
                <Link
                  className={containerStyles.imageContainer}
                  to={`/comunidad/${kebabCase(item.slug)}/`}
                >
                  <GatsbyImage
                    title={item.title}
                    className={containerStyles.image}
                    alt={item.title}
                    image={item.featuredImg.gatsbyImageData}
                  />
                </Link>
                <div className={containerStyles.content}>
                  <Link
                    className={containerStyles.title}
                    key={item.slug}
                    to={`/comunidad/${kebabCase(item.slug)}/`}
                  >
                    {item.title}
                  </Link>
                  <p className={containerStyles.description}>
                    {item.description.description}
                  </p>
                  <time>{item.fechaDePublicacion}</time>
                  <div className={containerStyles.buttonContainer}>
                    <Link
                      className="btn blue"
                      key={item.slug}
                      to={`/comunidad/${kebabCase(item.slug)}/`}
                    >
                      Leer más
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div>
        <h2 className="my-6 font-serif text-3xl font-bold">
          Conocé más editoriales
        </h2>
        <Publishers />
      </div>
    </Layout>
  )
}

export default PublisherSingleTemplate

export const pageQuery = graphql`
  query PublisherBySlug($slug: String!) {
    contentfulEditorial(slug: { eq: $slug }) {
      id
      title
      slug
      logo {
        file {
          url
        }
      }
      comunidad {
        title
        slug
        fechaDePublicacion(formatString: "LL", locale: "es")
        description {
          description
        }
        featuredImg {
          gatsbyImageData
        }
      }
    }
    allContentfulArticulos(
      filter: { publisher: { slug: { eq: $slug } } }
      sort: { fields: GameBuyPrice, order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug
          GameInStock
          GamePlayers
          GameDuration
          GameAuthor
          GameAges
          GameBuyPrice
          publisher {
            title
            slug
          }
          imagenDestacada {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 500
              height: 500
              formats: JPG
              backgroundColor: "#ffffff"
              placeholder: BLURRED
            )
            file {
              url
            }
          }
        }
      }
      totalCount
    }
  }
`
